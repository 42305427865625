import cc from 'card-validator';
import { DialogAction, DialogActions, Errors, Form, Input, Label, TextField } from '@troon/ui';
import { action, useSubmission } from '@solidjs/router';
import { createEffect, createSignal, Match, Show, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { CreditCardBrand, gql, getApiClient } from '../graphql';
import { revalidate } from '../graphql/cache';
import type { Track } from '@troon/analytics';
import type { CardNumberVerification } from 'card-validator/dist/card-number';
import type { ExpirationDateVerification } from 'card-validator/dist/expiration-date';
import type { Verification } from 'card-validator/dist/types';

type Props = {
	buttonText?: string;
	onSuccess?: (creditCardId: string) => Promise<void> | void;
	onCancel?: () => void;
};

export function AddCard(props: Props) {
	const [cardValidation, setCardValidation] = createSignal<CardNumberVerification | null>(null);
	const [expValidation, setExpValidation] = createSignal<ExpirationDateVerification | null>(null);
	const [cvvValidation, setCvvValidation] = createSignal<Verification | null>(null);
	const [postalValidation, setPostalValidation] = createSignal<Verification | null>(null);
	const track = useTrackEvent();

	const handleAddCard = addCard(track, props.onSuccess);

	const data = useSubmission(handleAddCard);
	createEffect(async () => {
		if (!data.pending && !data.error && data.result && !data.result.error) {
			data.clear();
		}
	});

	return (
		<Form
			// @ts-expect-error jumping through hoops on this one
			action={handleAddCard}
			document={addCardMutation}
			method="post"
		>
			<TextField name="cc-number">
				<Label>Credit card number</Label>
				<Input
					autocomplete="cc-number"
					inputMode="numeric"
					required
					onInput={(e) => setCardValidation(cc.number(e.target.value))}
				/>
			</TextField>

			<div class="grid grid-cols-3 gap-x-4 gap-y-2">
				<TextField name="cc-exp">
					<Label>Expiration</Label>
					<Input
						autocomplete="cc-exp"
						placeholder="MM/YY"
						required
						onInput={(e) => setExpValidation(cc.expirationDate(e.target.value))}
					/>
				</TextField>

				<TextField name="cc-csc">
					<Label>CVC</Label>
					<Input
						autocomplete="cc-csc"
						inputMode="numeric"
						required
						onInput={(e) => setCvvValidation(cc.cvv(e.target.value, cardValidation()?.card?.code.size))}
					/>
				</TextField>

				<TextField name="postal-code">
					<Label>Postal code</Label>
					<Input
						autocomplete="postal-code"
						required
						onInput={(e) => setPostalValidation(cc.postalCode(e.target.value, { minLength: 5 }))}
					/>
				</TextField>
			</div>

			<TextField name="cc-name">
				<Label>Name on card</Label>
				<Input required autocomplete="cc-name" />
			</TextField>

			<Errors />

			<DialogActions>
				<DialogAction
					type="submit"
					disabled={
						!cardValidation()?.isValid ||
						!expValidation()?.isValid ||
						!cvvValidation()?.isValid ||
						!postalValidation()?.isValid
					}
				>
					<Switch>
						<Match when={props.buttonText}>{props.buttonText}</Match>
						<Match when={!props.buttonText}>Add card</Match>
					</Switch>
				</DialogAction>
				<Show when={props.onCancel}>
					<DialogAction type="button" appearance="transparent" onClick={props.onCancel}>
						Cancel
					</DialogAction>
				</Show>
			</DialogActions>
		</Form>
	);
}

const addCard = (track: Track, onSuccess?: (cardId: string) => Promise<void> | void) =>
	action(async (formData) => {
		track('getCreditCardTokenRequest');
		const tokenRes = await getApiClient().mutation(createPaymentTokenMutation, {});
		if (tokenRes.error) {
			track('getCreditCardTokenFailure');
			return tokenRes;
		}
		track('getCreditCardTokenSuccess');
		const { token: uploadToken, tokenTypeId, uploadUrl } = tokenRes.data!.createPaymentToken;

		const exp = cc.expirationDate(formData.get('cc-exp'));
		let year = exp.year ? parseInt(exp.year, 10) : null;
		const month = exp.month ? parseInt(exp.month, 10) : null;
		let expirationYear = exp.year;
		let expirationMonth = exp.month;
		if (year && month) {
			year = year < 2000 ? 2000 + year : year;
			const parsed = new Date(year, month - 1);

			expirationMonth = `${parsed.getMonth() + 1}`.padStart(2, '0');
			expirationYear = `${parsed.getFullYear() - 2000}`;
		}

		track('storeCreditCardRequest');
		const uploadRes = await fetch(uploadUrl, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'omit',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token: { token: uploadToken, tokenTypeId },
				cardNumber: formData.get('cc-number'),
				nameOnCard: formData.get('cc-name'),
				expirationMonth,
				expirationYear,
				postalCode: formData.get('postal-code'),
			}),
		});

		const uploadData = await uploadRes.json();
		if (!uploadRes.ok || !uploadData.Token) {
			track('storeCreditCardFailure');
			return {
				error: {
					graphQLErrors: [
						{
							extensions: {
								displayMessage:
									'There was an error validating the credit card information. Please verify all fields and try again.',
								fields: Object.entries(uploadData.errors ?? {})
									.map(([key, errors]) =>
										key in ccFieldToName
											? {
													field: ccFieldToName[key as string],
													displayMessage: (errors as Array<string>)[0],
												}
											: false,
									)
									.filter(Boolean),
							},
						},
					],
				},
			};
		}
		track('storeCreditCardSuccess');

		const { card } = cc.number(formData.get('cc-number'))!;

		if (!card) {
			return {
				error: {
					graphQLErrors: [
						{
							extensions: {
								displayMessage:
									'There was an error validating the credit card information. Please verify all fields and try again.',
								fields: [],
							},
						},
					],
				},
			};
		}

		const data = {
			lastFour: formData.get('cc-number')!.slice(-4),
			brand: card.type in cardTypeToEnum ? cardTypeToEnum[card.type]! : CreditCardBrand.Other,
			token: uploadData.Token,
		};

		track('addStoredCreditCardRequest');
		const res = await getApiClient().mutation(addCardMutation, data, { additionalTypenames: ['CreditCard'] });
		if (!res.error && res.data?.addCreditCard) {
			track('addStoredCreditCardSuccess');
			await revalidate(['paymentMethods'], true);
			if (onSuccess) {
				await onSuccess(res.data!.addCreditCard.id);
			}
		} else {
			track('addStoredCreditCardFailure');
		}

		return res;
	}, 'addCard');

const createPaymentTokenMutation = gql(`
mutation createPaymentToken {
  createPaymentToken {
    token
		tokenTypeId
		uploadUrl
  }
}`);

const addCardMutation = gql(`
mutation addCreditCard($lastFour: String!, $brand: CreditCardBrand!, $token: String!) {
  addCreditCard(lastFour: $lastFour, brand: $brand, token: $token) {
    id
    lastFour
    brand
  }
}`);

const ccFieldToName: Record<string, string> = {
	CardNumber: 'cc-number',
	ExpirationMonth: 'cc-exp',
	ExpirationYear: 'cc-exp',
	NameOnCard: 'cc-name',
};

const cardTypeToEnum: Record<string, CreditCardBrand> = {
	'american-express': CreditCardBrand.Amex,
	visa: CreditCardBrand.Visa,
	mastercard: CreditCardBrand.Mastercard,
	discover: CreditCardBrand.Discover,
};
